import { registerApplication, start } from 'single-spa'


const apps = [
  'react',
  'culture',
  'culture-admin',
  'medicine',
  'medicine-admin',
  'gis-zkh-admin',
  'news',
  'votings',
  'events',
  'projects',
  'service-area',
  'service-area-admin',
  'goods-and-services',
  'control-tko',
  'control-tko-admin',
  'cleaning',
  'cleaning-admin',
  'excavation-admin',
  'rent-admin',
  'rent',
  'lighting-admin',
  'kumi-admin',
  'communications-admin',
  'hydrants-admin',
  'constructions-admin',
  'arm-admin',
  'alerts-admin',
  'alerts',
  'statistics',
  'video-control-admin',
  'full-video-admin',
  'yards-admin',
  'nto-admin',
  'nto',
  'requests-admin',
  'mobile-stations-admin',
  'warning-points-admin',
  'heating-admin',
  'excavation',
  'road-events-admin',
  'road-events',
  'covid-admin',
  'electric-admin',
  'initiatives',
  'user-profile',
]

if (window.__CONFIG__.cityName === 'Волгодонск') {
  apps.push('transport')
}

const noLayoutRoutes = [ 'login', 'login2', 'logout' ]

const vueRoutes = apps.filter(app => app !== 'react')
const allVueRoutes = apps.map(app => app.replace(/-admin/g, ''))
const vueAdminRoutes = vueRoutes.filter(app => app.includes('admin'))
const vueNoAdminRoutes = vueRoutes.filter(app => !app.includes('admin'))

const hasRoute = pathname => route =>
  new RegExp(`^((/${route})|(${`/admin/${route}`}))`).test(pathname)

const isReact = pathname => !allVueRoutes.some(hasRoute(pathname))
const isNoLayout = pathname => noLayoutRoutes.some(hasRoute(pathname))
const isAdminLayout = pathname => hasRoute(pathname)('admin')

const appsConfig = [
  {
    appName: '@smartsarov/admin-nav',
    elementId: 'header',
    activityFn: ({ pathname }) => isAdminLayout(pathname),
  },
  {
    appName: '@smartsarov/common-data',
    elementId: 'commonData',
    activityFn: () => true,
  },
  {
    appName: '@smartsarov/header',
    elementId: 'header',
    activityFn: ({ pathname }) =>
      !isAdminLayout(pathname) && !isNoLayout(pathname),
  },
  {
    appName: '@smartsarov/footer',
    elementId: 'footer',
    activityFn: ({ pathname }) =>
      !isAdminLayout(pathname) && !isNoLayout(pathname),
  },
  {
    appName: '@smartsarov/react-app',
    activityFn: ({ pathname }) => isReact(pathname),
  },
  ...vueAdminRoutes.map(appName => ({
    appName,
    activityFn: ({ pathname }) =>
      isAdminLayout(pathname) &&
      hasRoute(pathname)(appName.replace(/-admin/, '')),
  })),
  ...vueNoAdminRoutes.map(appName => ({
    appName,
    activityFn: ({ pathname }) =>
      !isAdminLayout(pathname) && hasRoute(pathname)(appName),
  })),
]

appsConfig.forEach(({ appName, elementId, activityFn }) => {
  registerApplication(appName, () => System.import(appName), activityFn, {
    domElement: document.getElementById(elementId || 'root'),
  })
})

start()
